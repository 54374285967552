<style scoped></style>
<template>
  <div>
    <v-dialog v-model="show_dialog" scrollable width="450">
      <v-card class="pb-0">
        <v-card-title class="primary_2 white--text" primary-title>
          <p class="text-h6">Add comment</p>
          <v-spacer></v-spacer>
          <v-icon large color="white" @click="show_dialog = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pt-3 pb-0">
          <v-form
            ref="add_comments_form"
            v-model="is_valid"
            lazy-validation
            @submit.prevent="addComment()"
          >
            <div>Comment</div>
            <v-text-field
              outlined
              dense
              :rules="$rules.REQUIRED_FIELD"
              v-model="comments"
              required
              placeholder="Enter comment"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-2">
          <v-btn
            color="secondary_2"
            :loading="btn_loader"
            :disabled="btn_loader"
            class="font-weight-bold white--text"
            @click="addComment()"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      is_valid: true,
      show_dialog: false,
      btn_loader: false,
      comments: "",
      customer_id: null,
    };
  },
  methods: {
    async openCommentsDialog(data) {
      this.show_dialog = true;
      this.customer_id = data.id;
      this.comments = data.internal_comment;
    },
    addComment() {
      if (this.$refs.add_comments_form.validate()) {
        const self = this;
        self.btn_loader = true;

        // let lookup_key = self.decrypt(self.$route.params.id);

        let form = new FormData();
        // form.append("customer_id", self.decrypt(self.$route.params.id));
        form.append("internal_comment", self.comments);

        const successHandler = () => {
          // if (response.status == 200) {
          this.$emit("update");
          this.$refs.add_comments_form.reset();
          this.show_dialog = false;
          // }
        };
        const finallyHandler = () => {
          self.btn_loader = false;
        };
        self.request_POST(
          self,
          self.$urls.ADD_INTERNAL_COMMENT + `${this.customer_id}/`,
          form,
          successHandler,
          null,
          null,
          finallyHandler
        );
      } else {
        this.showSnakeBar("error", "Please add comment");
      }
    },
  },
};
</script>
